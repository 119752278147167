import { Component, OnInit, Inject,ViewChild } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BrowserModule, DomSanitizer,  Meta, Title } from '@angular/platform-browser';
import { NgbModal, ModalDismissReasons, NgbModule, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { MatSnackBar, MatDialog } from '@angular/material';
import { ConfirmAlertComponent } from '../../../../../content/alerts/confirm-alert/confirm-alert.component';


//Providers
import { CapacitacionesProviderService } from '../../../../../providers/Central/capacitaciones/capacitaciones-provider.service';

@Component({
  selector: 'app-informacion-capacitaciones',
  templateUrl: './informacion-capacitaciones.component.html',
  styleUrls: ['./informacion-capacitaciones.component.css']
})
export class InformacionCapacitacionesComponent implements OnInit {

  private token: any = "";
  public idUsuario: any;
  private tipoUsuario: any;
  private sesion:any;
  public capacitaciones: any = [];
  public c: any = false;
  public resultados: any = {};

  constructor(  private router: Router,  public snackBar: MatSnackBar, private capacitacionesProvider: CapacitacionesProviderService,  public dialog: MatDialog, private modalService: NgbModal ) {
  }

  ngOnInit() {
      this.capacitaciones.length = 0;
    this.token = this.obtenerToken();
    if( this.token != false ){
      let datos = {
        'idUsuario': this.idUsuario,
        'tipo':      this.tipoUsuario
      }
      this.cargarDatosCapacitaciones( this.token, datos );
    }
  }

  /**
  * Obtiene el token almacenado en el localStorage
  *
  * @returns String con la cadena del token o Booleano si no hay sesion abierta
  */
  obtenerToken(){
    let salida: any;
    let datos =  JSON.parse( localStorage.getItem( 'sesion' ) );
    if( datos != null ){
      if( datos.sesion == 0 ){
        salida = false;
      }else{
        this.idUsuario = datos.idUsuario;
        this.tipoUsuario = datos.tipoUsuario;
        salida = datos.token;
      }
    }else{
      salida = false;
    }
    return salida;
  }

  /* Carga los datos del usuario
  *
  * @param String token almacenado
  * @param Object datos del usuario( id del usuario y tipo )
  * @returns respuesta del servdor
  */
  cargarDatosCapacitaciones( token: any, datosUsuario: any ){
    this.capacitacionesProvider.consultarCapacitacionesEmpleado( token, datosUsuario).subscribe(
      ( data ) => {
        let datos = data.datos;
        let that  = this;
        that.capacitaciones = datos;
      },
      ( error ) => {
        if( error.status == 401){
          this.mostrarAlerta( "La sesión ha expirado", 4000 );
          this.logOut();
        }
        if( error.status == 400 || error.status == 500 ){

            console.log( this.capacitaciones );
          this.mostrarAlerta( error.error.Error, 4000 );
        }
      }
    );
  }

  /**
   * Abre el modal de a cuerdo al id
   *
   * @param String id del modal que se va ha abrir
   * @param Integer posicion de la prueba en el arreglo
   *
   * @returns Abre modal
   */
  abrirModal( content, index ) {
   this.modalService.open(content, { windowClass: 'dark-modal-profesionales' });
   this.consultarResultadosPrueba( index );
  }

  /**
   * Obtiene los resultados de la prueba
   *
   * @param Integer posicion de la prueba en el arreglo
   *
   * @returns Abre modal
   */
  consultarResultadosPrueba( index ) {
    let prueba = this.capacitaciones[index];
    this.capacitacionesProvider.consultarResultadosPrueba( this.token, prueba.idAplicacion ).subscribe(
      ( data ) => {
        let datos = data.datos;
        let that  = this;
        let resultados = {
          'idCapacitacion': prueba.idCapacitacion,
          'titulo':         prueba.titulo,
          'descripcion':    prueba.descripcion,
          'idAplicacion':   datos.idAplicacion,
          'acertadas':      datos.acertadas,
          'total':          datos.total,
          'porcentaje':     datos.porcentaje,
          'fechaPresentacion': datos.fechaPresentacion
        }
       that.resultados = resultados;
      },
      ( error ) => {
        if( error.status == 401){
          this.mostrarAlerta( "La sesión ha expirado", 4000 );
          this.logOut();
        }
        if( error.status == 400 || error.status == 500 ){
          this.mostrarAlerta( error.error.Error, 4000 );
        }
      }
    );

  }


  /**
  * Realiza el cierre de sesion
  *
  * @returns hace el cierre de sesion y redirecciona al login
  */
  logOut(){
    let datos = {
      sesion: 0
    }
    localStorage.setItem( 'sesion', JSON.stringify( datos ) );
    this.sesion = false;
    location.href='/inicio';
  }

  /* muestra las alertas de mensajes al usuario
  *
  * @param String mensaje para mostrar
  * @param Integer tiempo en milisegundos que se va a mostrar el mensaje
  * @returns muestra el mensaje
  */
  mostrarAlerta( mensaje, tiempo ){
    this.snackBar.open( mensaje, 'Aceptar', {
      duration: tiempo,
    });
  }
}
