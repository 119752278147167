import { Component, OnInit, Inject,ViewChild } from '@angular/core';
import { DOCUMENT, Location, formatDate } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BrowserModule, DomSanitizer,  Meta, Title } from '@angular/platform-browser';
import { NgbModal, ModalDismissReasons, NgbModule, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { MatSnackBar, MatDialog } from '@angular/material';
import { ConfirmAlertComponent } from '../../../../../content/alerts/confirm-alert/confirm-alert.component';


//Providers
import { UsuarioProviderService } from '../../../../../providers/Central/usuario/usuario-provider.service';


@Component({
  selector: 'app-informacion-tributaria',
  templateUrl: './informacion-tributaria.component.html',
  styleUrls: ['./informacion-tributaria.component.css']
})
export class InformacionTributariaComponent implements OnInit {

  private token: any;
  private idProveedor: any;
  private tipoUsuario: any = "";
  private sesion: any;
  public proveedor: any = {};
  public regimenes: any = [];
  public naturalezas: any = [];

  constructor( private router: Router,  public snackBar: MatSnackBar, private usuarioProvider: UsuarioProviderService,  public dialog: MatDialog, private modalService: NgbModal  ) { }

  ngOnInit() {
    this.token = this.obtenerToken();
    if( this.token != false ){
      let datos = {
        'idUsuario': this.idProveedor,
        'tipo':      this.tipoUsuario
      }
      this.cargarDatosUsuario( this.token, datos );
    }
  }

  /**
  * Obtiene el token almacenado en el localStorage
  *
  * @returns String con la cadena del token o Booleano si no hay sesion abierta
  */
  obtenerToken(){
    let salida: any;
    let datos =  JSON.parse( localStorage.getItem( 'sesion' ) );
    if( datos != null ){
      if( datos.sesion == 0 ){
        salida = false;
      }else{
        this.idProveedor = datos.idUsuario;
        this.tipoUsuario = datos.tipoUsuario;
        salida = datos.token;
      }
    }else{
      salida = false;
    }
    return salida;
  }

  /**
  * Carga los datos del usuario
  *
  * @param String token almacenado
  * @param Object datos del usuario( id del usuario y tipo )
  * @returns respuesta del servdor
  */
  cargarDatosUsuario( token: any, datosUsuario: any ){
    //carga de las opciones de naturaleza juridica y regimen iva
    this.usuarioProvider.consultarNaturalezaRegimen( token, datosUsuario ).subscribe(
      ( data )  =>  {
        let datos = data.datos;
        let that = this;
        that.regimenes = datos.regimenIva,
        that.naturalezas = datos.naturaleza
      },
      ( error ) =>  {
        if( error.status == 401){
          this.mostrarAlerta( "La sesión ha expirado", 4000 );
          this.logOut();
        }
        if( error.status == 400 || error.status == 500 ){
          this.mostrarAlerta( error.error.status, 4000 );
        }
      }
    );
    //carga de los datos del usuario
    this.usuarioProvider.consultarInformacionTributariaProveedor( token, datosUsuario ).subscribe(
      ( data )  =>  {
        let datos = data.datos;
        let that = this;
        that.proveedor = datos;
      },
      ( error ) =>  {
        if( error.status == 401){
          this.mostrarAlerta( "La sesión ha expirado", 4000 );
          this.logOut();
        }
        if( error.status == 400 || error.status == 500 ){
          this.mostrarAlerta( error.error.status, 4000 );
        }
      }
    );
  }

  /**
  * Envia los datos para almacenarlos en la base de datos
  *
  * @returns respuesta del servidor
  */
  guardarDatos(){
    let datos = {
      'idProveedor':  this.idProveedor,
      'tipoUsuario':  this.tipoUsuario,
      'datos'      :  this.proveedor
    }
    this.usuarioProvider.actualizarInformacionTributaria( this.token, datos ).subscribe(
      ( data )  =>  {
        let datos = data.datos;
        let that = this;
        that.mostrarAlerta( "La información se actualizo correctamente", 4000 );
        location.reload();
      },
      ( error ) =>  {
        if( error.status == 401){
          this.mostrarAlerta( "La sesión ha expirado", 4000 );
          this.logOut();
        }
        if( error.status == 400 || error.status == 500 ){
          this.mostrarAlerta( error.error.Error, 4000 );
        }
      }
    );
  }

  /**
  * Realiza el cierre de sesion
  *
  * @returns hace el cierre de sesion y redirecciona al login
  */
  logOut(){
    let datos = {
      sesion: 0
    }
    localStorage.setItem( 'sesion', JSON.stringify( datos ) );
    this.sesion = false;
    location.href='/inicio';
  }

  /* muestra las alertas de mensajes al usuario
  *
  * @param String mensaje para mostrar
  * @param Integer tiempo en milisegundos que se va a mostrar el mensaje
  * @returns muestra el mensaje
  */
  mostrarAlerta( mensaje, tiempo ){
    this.snackBar.open( mensaje, 'Aceptar', {
      duration: tiempo,
    });
  }
}
