import { Component, OnInit, Inject,ViewChild } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BrowserModule, DomSanitizer,  Meta, Title } from '@angular/platform-browser';
import { NgbModal, ModalDismissReasons, NgbModule, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { MatSnackBar, MatDialog } from '@angular/material';
import { ConfirmAlertComponent } from '../../../../../content/alerts/confirm-alert/confirm-alert.component';


//Providers
import { UsuarioProviderService } from '../../../../../providers/Central/usuario/usuario-provider.service';

@Component({
  selector: 'app-informacion-empleado',
  templateUrl: './informacion-empleado.component.html',
  styleUrls: ['./informacion-empleado.component.css']
})
export class InformacionEmpleadoComponent implements OnInit {

  public usuario: any = {};
  private token: any = "";
  public idUsuario: any;
  private tipoUsuario: any;
  private sesion:any;
  public telefenoNuevo: any = {};
  public contactoNuevo: any = {};

  constructor( private router: Router,  public snackBar: MatSnackBar, private usuarioProvider: UsuarioProviderService,  public dialog: MatDialog, private modalService: NgbModal  ) {
  }

  ngOnInit() {
    this.token = this.obtenerToken();
    if( this.token != false ){
      let datos = {
        'idUsuario': this.idUsuario,
        'tipo':      this.tipoUsuario
      }
      this.cargarDatosUsuario( this.token, datos );
    }
  }

  /**
  * Obtiene el token almacenado en el localStorage
  *
  * @returns String con la cadena del token o Booleano si no hay sesion abierta
  */
  obtenerToken(){
    let salida: any;
    let datos =  JSON.parse( localStorage.getItem( 'sesion' ) );
    if( datos != null ){
      if( datos.sesion == 0 ){
        salida = false;
      }else{
        this.idUsuario = datos.idUsuario;
        this.tipoUsuario = datos.tipoUsuario;
        salida = datos.token;
      }
    }else{
      salida = false;
    }
    return salida;
  }

  /* Carga los datos del usuario
  *
  * @param String token almacenado
  * @param Object datos del usuario( id del usuario y tipo )
  * @returns respuesta del servdor
  */
  cargarDatosUsuario( token: any, datosUsuario: any ){
    this.usuarioProvider.consultarDatosCompletosUsuario( token, datosUsuario).subscribe(
      ( data ) => {
        let datos = data.datos;
        let that  = this;
        that.usuario = datos;
      },
      ( error ) => {
        if( error.status == 401){
          this.mostrarAlerta( "La sesión ha expirado", 4000 );
          this.logOut();
        }
        if( error.status == 400 || error.status == 500 ){
          this.mostrarAlerta( error.error.Error, 4000 );
        }
      }
    );
  }

  /**
  * Abre el modal de a cuerdo al id
  *
  * @param String id del modal que se va ha abrir
  *
  * @returns Abre modal
  */
  abrirModal( content ) {
    this.modalService.open(content, { windowClass: 'dark-modal-profesionales' });
  }

  /**
  * Abre el modal para agregar un nuevo telefono del empleado
  *
  * @param Content
  *
  * @returns Abre modal
  */
  agregarTelefono(){
    let arraytelefonos = this.usuario.telefonos;
    let datos = {
      "idTelefono": 0,
      "telefono": this.telefenoNuevo.telefono,
      "descripcion": this.telefenoNuevo.descripcion
    };
    arraytelefonos.push( datos );
    this.usuario.telefonos = arraytelefonos;
  }

  /**
  * Elimina el telefono del listado
  *
  * @param Integer posicion en el array de contactos
  *
  * @returns Elimina telefono
  */
  eliminarTelefono( index: any ){
    let telefono = this.usuario.telefonos[index];
    const dialogRef = this.dialog.open(ConfirmAlertComponent, {
    width: '250px',
    });
    dialogRef.afterClosed().subscribe(
      ( result ) => {
        if( result ){
          if( telefono.idTelefono == 0 ){
            this.usuario.telefonos.splice(index, 1);
          }else{
            this.usuarioProvider.eliminarTelefonoEmpleado( this.token, telefono.idTelefono ).subscribe(
              ( data ) => {
                this.mostrarAlerta( "El teléfono se elimino correctamente.", 5000);
                this.usuario.telefonos.splice(index, 1);
              },
              ( error ) => {
                if( error.status == 401){
                  this.mostrarAlerta( "La sesión ha expirado", 4000 );
                  this.logOut();
                }
                if( error.status == 400 || error.status == 500 ){
                  this.mostrarAlerta( error.error.Error, 4000 );
                }
              }
            );
          }
        }
      }
    );
  }

  /**
  * Agrega el contacto a la lista
  *
  * @param Content
  *
  * @returns Abre modal
  */
  agregarContacto(){
    let datos = {
      "idContacto": 0,
      "nombre": this.contactoNuevo.nombre,
      "parentesco": this.contactoNuevo.parentesco,
      "telefono": this.contactoNuevo.telefono,
      "direccion": this.contactoNuevo.direccion,
      "ciudad": this.contactoNuevo.ciudad
    };
    this.usuario.contactos.push( datos );
  }

  /**
  * Elimina el contacto del listado
  *
  * @param Integer posicion en el array de contactos
  *
  * @returns elimina contacto
  */
  eliminarContacto( index: any ){
    let contacto = this.usuario.contactos[index];
    const dialogRef = this.dialog.open(ConfirmAlertComponent, {
    width: '250px',
    });
    dialogRef.afterClosed().subscribe(
      ( result ) => {
        if( result ){
          if( contacto.idContacto == 0 ){
            this.usuario.contactos.splice(index, 1);
          }else{
            this.usuarioProvider.eliminarTelefonoEmpleado( this.token,contacto.idContacto ).subscribe(
              ( data ) => {
                this.mostrarAlerta( "El contacto se elimino correctamente.", 5000);
                this.usuario.contactos.splice(index, 1);
              },
              ( error ) => {
                if( error.status == 401){
                  this.mostrarAlerta( "La sesión ha expirado", 4000 );
                  this.logOut();
                }
                if( error.status == 400 || error.status == 500 ){
                  this.mostrarAlerta( error.error.Error, 4000 );
                }
              }
            );
          }
        }
      }
    );

  }

  /**
  * Guarda los datos del empleado
  *
  *
  * @returns elimina contacto
  */
  guardarDatos(){
    this.usuario.tipoUsuario = this.tipoUsuario;
    this.usuarioProvider.actualizarInformacionUsuario( this.token, this.usuario ).subscribe(
      ( data ) => {
        let dato = data.datos;
        this.mostrarAlerta( "Los datos se actualizaron correctamente.", 5000 );
        let datos = {
          'idUsuario': this.idUsuario,
          'tipo':      this.tipoUsuario
        }
        this.cargarDatosUsuario( this.token, datos );
      },
      ( error ) => {
        if( error.status == 401){
          this.mostrarAlerta( "La sesión ha expirado", 4000 );
          this.logOut();
        }
        if( error.status == 400 || error.status == 500 ){
          this.mostrarAlerta( error.error.Error, 4000 );
        }
      }
    );
  }

  /**
  * Realiza el cierre de sesion
  *
  * @returns hace el cierre de sesion y redirecciona al login
  */
  logOut(){
    let datos = {
      sesion: 0
    }
    localStorage.setItem( 'sesion', JSON.stringify( datos ) );
    this.sesion = false;
    location.href='/inicio';
  }

  /* muestra las alertas de mensajes al usuario
  *
  * @param String mensaje para mostrar
  * @param Integer tiempo en milisegundos que se va a mostrar el mensaje
  * @returns muestra el mensaje
  */
  mostrarAlerta( mensaje, tiempo ){
    this.snackBar.open( mensaje, 'Aceptar', {
      duration: tiempo,
    });
  }

}
