import { Component, OnInit, Inject,ViewChild } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BrowserModule, DomSanitizer,  Meta, Title } from '@angular/platform-browser';
import { NgbModal, ModalDismissReasons, NgbModule, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { MatSnackBar, MatDialog } from '@angular/material';
import { ConfirmAlertComponent } from '../../../../../content/alerts/confirm-alert/confirm-alert.component';


//Providers
import { UsuarioProviderService } from '../../../../../providers/Central/usuario/usuario-provider.service';


@Component({
  selector: 'app-estudios-aspirante',
  templateUrl: './estudios-aspirante.component.html',
  styleUrls: ['./estudios-aspirante.component.css']
})
export class EstudiosAspiranteComponent implements OnInit {

  public usuario: any = {};
  public certificacion: any = {};
  public certificacionNueva: any = {};
  public certificaciones: any = [];
  private idUsuario: any = "";
  private tipoUsuario: any = "";
  private sesion: any;
  private token: any = "";

  constructor( private router: Router,  public snackBar: MatSnackBar, private usuarioProvider: UsuarioProviderService,  public dialog: MatDialog, private modalService: NgbModal ) { }

  ngOnInit() {
    this.token = this.obtenerToken();
    if( this.token != false ){
      let datos = {
        'idUsuario': this.idUsuario,
        'tipo':      this.tipoUsuario
      }
      this.cargarDatosUsuario( this.token, datos );
    }
  }

  /**
  * Obtiene el token almacenado en el localStorage
  *
  * @returns String con la cadena del token o Booleano si no hay sesion abierta
  */
  obtenerToken(){
    let salida: any;
    let datos =  JSON.parse( localStorage.getItem( 'sesion' ) );
    if( datos != null ){
      if( datos.sesion == 0 ){
        salida = false;
      }else{
        this.idUsuario = datos.idUsuario;
        this.tipoUsuario = datos.tipoUsuario;
        salida = datos.token;
      }
    }else{
      salida = false;
    }
    return salida;
  }

  /* Carga los datos del usuario
  *
  * @param String token almacenado
  * @param Object datos del usuario( id del usuario y tipo )
  * @returns respuesta del servdor
  */
  cargarDatosUsuario( token: any, datosUsuario: any ){
    this.usuarioProvider.consultarCertificacionesEstudioAspirante( token, datosUsuario ).subscribe(
      ( data ) => {
        let datos = data.datos;
        let that = this;
        that.certificaciones = datos;
      },
      ( error ) => {
        if( error.status == 401){
          this.mostrarAlerta( "La sesión ha expirado", 4000 );
          this.logOut();
        }
        if( error.status == 400 || error.status == 500 ){
          this.mostrarAlerta( error.error.status, 4000 );
        }
      }
    );
  }

  /**
  * Agrega la certificacion a la lista
  *
  * @returns
  */
  agregarCertificacion(  ) {
    let datos = {
      'idEstudio':    0,
      'titulo':       this.certificacionNueva.titulo,
      'institucion':  this.certificacionNueva.institucion,
      'tipo':         this.certificacionNueva.tipo,
      'fechaTerminacion': this.certificacionNueva.fechaTerminacion
    };
    this.certificaciones.push( datos );
    this.certificacionNueva = {};
  }

  /**
  * Elimina la certificacion de la lista de estudios
  *
  * @param Integer posicion del certificado dentro del arreglo de certificaciones
  * @param String id del certificado en la base de datos ( idEstudio )
  *
  * @returns eliminacion del elemento y / o respuesta del servidor
  */
  eliminarCertificacion( index, idCertificado ){
    const dialogRef = this.dialog.open(ConfirmAlertComponent, {
    width: '250px',
    });
    dialogRef.afterClosed().subscribe(
      ( result ) => {
        if( result ){
          if( idCertificado == 0 ){
            this.certificaciones.splice(index, 1);
          }else{
            this.usuarioProvider.eliminarCertificadoEstudioAspirante( this.token, idCertificado ).subscribe(
              ( data ) => {
                this.mostrarAlerta( "El elemento ha sido borrado.", 4000 );
                this.certificaciones.splice(index, 1);
              },
              ( error ) => {
                if( error.status == 401){
                  this.mostrarAlerta( "La sesión ha expirado", 4000 );
                  this.logOut();
                }
                if( error.status == 400 || error.status == 500 ){
                  this.mostrarAlerta( error.error.Error, 4000 );
                }
              }
            );
          }
        }
      }
    );

  }

  /**
  * Envia la solicitud a la api para actualizar los datos de las certificaciones academicas del aspirante
  *
  * @returns respuesta del servidor
  */
  guardarDatos(){
    let datos = {
      'idAspirante':  this.idUsuario,
      'tipo':         this.tipoUsuario,
      'certificados': this.certificaciones
    }
    this.usuarioProvider.actualizarCertificacionesEstudioAspirante( this.token, datos ).subscribe(
      ( data ) => {
        let datos = data.datos;
        this.mostrarAlerta("La información se guardo correctamente.", 4000);
        let datosUsuario = {
          'idUsuario': this.idUsuario,
          'tipo':      this.tipoUsuario
        }
        this.cargarDatosUsuario( this.token, datosUsuario );
      },
      ( error ) => {
        if( error.status == 401){
          this.mostrarAlerta( "La sesión ha expirado", 4000 );
          this.logOut();
        }
        if( error.status == 400 || error.status == 500 ){
          this.mostrarAlerta( error.error.Error, 4000 );
        }
      }
    );
  }

  /**
  * Abre el modal de a cuerdo al id
  *
  * @param String id del modal que se va ha abrir
  *
  * @returns Abre modal
  */
  abrirModal( content ) {
    this.modalService.open(content, { windowClass: 'dark-modal-profesionales' });
  }

  /**
  * Realiza el cierre de sesion
  *
  * @returns hace el cierre de sesion y redirecciona al login
  */
  logOut(){
    let datos = {
      sesion: 0
    }
    localStorage.setItem( 'sesion', JSON.stringify( datos ) );
    this.sesion = false;
    location.href='/inicio';
  }

  /* muestra las alertas de mensajes al usuario
  *
  * @param String mensaje para mostrar
  * @param Integer tiempo en milisegundos que se va a mostrar el mensaje
  * @returns muestra el mensaje
  */
  mostrarAlerta( mensaje, tiempo ){
    this.snackBar.open( mensaje, 'Aceptar', {
      duration: tiempo,
    });
  }

}
