import { Component, OnInit, Inject,ViewChild } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BrowserModule, DomSanitizer,  Meta, Title } from '@angular/platform-browser';
import { NgbModule, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { MatSnackBar, MatDialog } from '@angular/material';

//Providers
import { UsuarioProviderService } from '../../../../providers/Central/usuario/usuario-provider.service';

@Component({
  selector: 'app-perfil-empleado',
  templateUrl: './perfil-empleado.component.html',
  styleUrls: ['./perfil-empleado.component.css']
})
export class PerfilEmpleadoComponent implements OnInit {

  public usuario: any = {};
  private token: any = "";
  public idUsuario: any;
  private tipoUsuario: any;
  private sesion:any;

  constructor( private router: Router,  public snackBar: MatSnackBar, private usuarioProvider: UsuarioProviderService  ) { }

  ngOnInit() {
    this.token = this.obtenerToken();
    if( this.token != false ){
      let datos = {
        'idUsuario': this.idUsuario,
        'tipo':      this.tipoUsuario
      }
      this.cargarDatosUsuario( this.token, datos );
    }
  }

  /**
  * Obtiene el token almacenado en el localStorage
  *
  * @returns String con la cadena del token o Booleano si no hay sesion abierta
  */
  obtenerToken(){
    let salida: any;
    let datos =  JSON.parse( localStorage.getItem( 'sesion' ) );
    if( datos != null ){
      if( datos.sesion == 0 ){
        salida = false;
      }else{
        this.idUsuario = datos.idUsuario;
        this.tipoUsuario = datos.tipoUsuario;
        salida = datos.token;
      }
    }else{
      salida = false;
    }
    return salida;
  }

  /* Carga los datos del usuario
  *
  * @param String token almacenado
  * @param Object datos del usuario( id del usuario y tipo )
  * @returns respuesta del servdor
  */
  cargarDatosUsuario( token: any, datosUsuario: any ){
    this.usuarioProvider.consultarUsuario( token, datosUsuario).subscribe(
      ( data ) => {
        let datos = data.datos;
        let that  = this;
        that.usuario = datos;
      },
      ( error ) => {
        if( error.status == 401){
          this.mostrarAlerta( "La sesión ha expirado", 4000 );
          this.logOut();
        }
        if( error.status == 400 || error.status == 500 ){
          this.mostrarAlerta( error.error.Error, 4000 );
        }
      }
    );
  }

  /**
  * Realiza el cierre de sesion
  *
  * @returns hace el cierre de sesion y redirecciona al login
  */
  logOut(){
    let datos = {
      sesion: 0
    }
    localStorage.setItem( 'sesion', JSON.stringify( datos ) );
    this.sesion = false;
    location.href='/inicio';
  }

  /* muestra las alertas de mensajes al usuario
  *
  * @param String mensaje para mostrar
  * @param Integer tiempo en milisegundos que se va a mostrar el mensaje
  * @returns muestra el mensaje
  */
  mostrarAlerta( mensaje, tiempo ){
    this.snackBar.open( mensaje, 'Aceptar', {
      duration: tiempo,
    });
  }

}
