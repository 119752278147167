import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { ENV } from '../../../../conf/ENV';
import { ResponseInformationObjectInterface } from '../../../wrappers/response-information-object-interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CapacitacionesProviderService {

  public environment: any;
  public configUrl: any;

  constructor( private http: HttpClient, private env: ENV ) {
    this.environment = env.getEnv();
    this.configUrl = this.environment.API_URL + "/capacitaciones/";
  }



  /**
  * Envia la solicitud a la Api para consultar los datos de las capacitaciones asociadas al empleado
  *
  * @param String token almacenados
  * @param Object datos del usuario ( idUsuario, tipo usuario )
  *
  * @returns respuesta del servidor o mensaje de error
  */
  consultarCapacitacionesEmpleado( token: any, datos: any ){
    let accion = 'consultarCapacitacionesEmpleado/';
    let headers = new HttpHeaders({
     'content-type': 'application/json',
     'Authorization': 'Bearer '+token,
    });
    var options =  {
       headers: headers
    };
    return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
  }

  /**
  * Envia la solicitud a la Api para consultar los datos de las capacitacion del empleado
  *
  * @param String token almacenados
  * @param Integer id de la capacitacion
  *
  * @returns respuesta del servidor o mensaje de error
  */
  consultarCapacitacion( token: any, idCapacitacion: any, idEmpleado: any ){
    let accion = 'consultarCapacitacion/'+idCapacitacion+'/'+idEmpleado;
    let headers = new HttpHeaders({
      'content-type': 'application/json',
      'Authorization': 'Bearer '+token,
    });
    var options =  {
        headers: headers
    };
    return this.http.get<ResponseInformationObjectInterface>( this.configUrl+accion, options);

  }

  /**
  * Envia la solicitud a la Api para enviar la solucion de la prueba de la capacitacion
  *
  * @param String token almacenados
  * @param Object los datos de la prueba
  *
  * @returns respuesta del servidor o mensaje de error
  */
  guardarCuestionarioCapacitacion( token: any, datos: any ){
    let accion = 'guardarCuestionarioCapacitacion/';
    let headers = new HttpHeaders({
     'content-type': 'application/json',
     'Authorization': 'Bearer '+token,
    });
    var options =  {
       headers: headers
    };
    return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
  }

  /**
  * Envia la solicitud a la Api para consultar los datos de los resultados de la capacitacion
  *
  * @param String token almacenados
  * @param Integer id de la aplicacion de la capacitacion
  *
  * @returns respuesta del servidor o mensaje de error
  */
  consultarResultadosPrueba( token: any, idAplicacion: any ){
    let accion = 'consultarResultadosPrueba/'+idAplicacion;
    let headers = new HttpHeaders({
      'content-type': 'application/json',
      'Authorization': 'Bearer '+token,
    });
    var options =  {
        headers: headers
    };
    return this.http.get<ResponseInformationObjectInterface>( this.configUrl+accion, options);

  }
}
