import { Component, OnInit, Inject,ViewChild } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BrowserModule, DomSanitizer,  Meta, Title } from '@angular/platform-browser';
import { NgbModal, ModalDismissReasons, NgbModule, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { MatSnackBar, MatDialog } from '@angular/material';
import { ConfirmAlertComponent } from '../../../../../content/alerts/confirm-alert/confirm-alert.component';

//Providers
import { UsuarioProviderService } from '../../../../../providers/Central/usuario/usuario-provider.service';


@Component({
  selector: 'app-aplicacion-ofertas',
  templateUrl: './aplicacion-ofertas.component.html',
  styleUrls: ['./aplicacion-ofertas.component.css']
})
export class AplicacionOfertasComponent implements OnInit {

  public ofertas: any = [];
  private idUsuario: any = "";
  private tipoUsuario: any = "";
  private sesion: any;
  private token: any = "";

  constructor(  private router: Router,  public snackBar: MatSnackBar, private usuarioProvider: UsuarioProviderService,  public dialog: MatDialog, private modalService: NgbModal  ) { }

  ngOnInit() {
    this.token = this.obtenerToken();
    this.cargarOfertasLaborales( );
  }

  /**
  * Obtiene el token almacenado en el localStorage
  *
  * @returns String con la cadena del token o Booleano si no hay sesion abierta
  */
  obtenerToken(){
    let salida: any;
    let datos =  JSON.parse( localStorage.getItem( 'sesion' ) );
    if( datos != null ){
      if( datos.sesion == 0 ){
        salida = false;
      }else{
        this.idUsuario = datos.idUsuario;
        this.tipoUsuario = datos.tipoUsuario;
        salida = datos.token;
      }
    }else{
      salida = false;
    }
    return salida;
  }

  /**
  * Consulta de las ofertas laborales a las que aplico el aspirante
  *
  * @returns String con la cadena del token o Booleano si no hay sesion abierta
  */
  cargarOfertasLaborales(){
    let datos = {
      'idAspirante':  this.idUsuario,
      'tipo':         this.tipoUsuario
    }
    this.usuarioProvider.consultarOfertasLaboralesAspirante( this.token, datos ).subscribe(
      ( data )  =>  {
        let datos = data.datos;
        let that = this;
        that.ofertas = datos;
      },
      ( error ) =>  {
        this.ofertas = [];
        if( error.status == 401){
          this.mostrarAlerta( "La sesión ha expirado", 4000 );
          this.logOut();
        }
        if( error.status == 400 || error.status == 500 ){
          this.mostrarAlerta( error.error.Error, 4000 );
        }
      }
    );
  }

  /**
  * Envia la solicitud a la api para eliminar la aplicacion a la oferta laboral
  *
  * @param Integer  idAplicacion
  * @param Integer  posicion en el array del elemento
  *
  * @returns String con la cadena del token o Booleano si no hay sesion abierta
  */
  eliminarOfertaLabora( idAplicacion: any, index: any ){
    const dialogRef = this.dialog.open(ConfirmAlertComponent, {
    width: '250px',
    });
    dialogRef.afterClosed().subscribe(
      ( result ) => {
        if( result ){
          this.usuarioProvider.eliminarOfertaLaboralAspirante( this.token, idAplicacion ).subscribe(
            ( data )  =>  {
                this.ofertas.splice(index, 1);
                this.mostrarAlerta( "Lo oferta fue eliminada correctamente.", 4000 );
            },
            ( error ) =>  {
              if( error.status == 401){
                this.mostrarAlerta( "La sesión ha expirado", 4000 );
                this.logOut();
              }
              if( error.status == 400 || error.status == 500 ){
                this.mostrarAlerta( error.error.status, 4000 );
              }
            }
          );
        }
      }
    );


  }
  /**
  * Realiza el cierre de sesion
  *
  * @returns hace el cierre de sesion y redirecciona al login
  */
  logOut(){
    let datos = {
      sesion: 0
    }
    localStorage.setItem( 'sesion', JSON.stringify( datos ) );
    this.sesion = false;
    location.href='/inicio';
  }

  /* muestra las alertas de mensajes al usuario
  *
  * @param String mensaje para mostrar
  * @param Integer tiempo en milisegundos que se va a mostrar el mensaje
  * @returns muestra el mensaje
  */
  mostrarAlerta( mensaje, tiempo ){
    this.snackBar.open( mensaje, 'Aceptar', {
      duration: tiempo,
    });
  }

}
