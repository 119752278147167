import { Component, OnInit, Inject,ViewChild } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BrowserModule, DomSanitizer,  Meta, Title } from '@angular/platform-browser';
import { NgbModule, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { MatSnackBar, MatDialog } from '@angular/material';
import { Md5 } from 'ts-md5/dist/md5';

//provider
import { LoginRegistroProviderService } from '../../../../providers/Central/loginRegistro/login-registro-provider.service';

@Component({
  selector: 'app-login-empleado',
  templateUrl: './login-empleado.component.html',
  styleUrls: ['./login-empleado.component.css']
})
export class LoginEmpleadoComponent implements OnInit {

  public usuario: any = "";
  public contrasena: any = "";
  private token: any = "";
  public vistaMensaje: any = false;
  public mensaje: any = "";

  constructor( private router: Router, private meta: Meta, private title: Title, @Inject(DOCUMENT) private document: any, private location: Location, public snackBar: MatSnackBar, private loginProvider: LoginRegistroProviderService ) { }

  ngOnInit() {
    this.token = this.obtenerToken();
    if( this.token != false ){
      location.href='/perfilEmpleado';
    }
  }

  /**
   * Realiza el inicio de sesion
   *
   * @returns respuesta del servidor
   */
  login(){
    this.vistaMensaje = false;
    this.mensaje = "";
    let datos = {
     'usuario': this.usuario,
     'contrasena': Md5.hashStr( this.contrasena ),
     'tipo': 1
    }
    this.loginProvider.iniciarSesionEmpleado( datos ).subscribe(
     ( data ) => {
       let datos = data.datos;
       let that  = this;
       that.cargarLocalStorage( datos );
       location.href='/perfilEmpleado';
     },
     ( error ) => {
      if( error.status == 500 || error.status == 400 ){
        this.vistaMensaje = true;
        this.mensaje = error.error.status;
        this.mostrarAlerta( error.error.status, 4000);
      }
     }
    );

  }

   /**
   * Obtiene los datos del token almacenados
   *
   * @returns retorna el token, o un false si no hay sesion
   */
  obtenerToken(){
    let salida: any;
    let datos =  JSON.parse( localStorage.getItem( 'sesion' ) );
    if( datos.sesion == 0 ){
      salida = false;
    }else{
      salida = datos.token;
    }
    return salida;
  }

  /**
  * Guarda los datos de la sesion en el localStorage
  *
  * @returns localStorage
  */
  cargarLocalStorage( datos ){
    let datosSesion = {
      sesion: 1,
      idUsuario: datos.id,
      token: datos.token,
      tipoUsuario: datos.tipo
    }
    localStorage.setItem( 'sesion', JSON.stringify( datosSesion ) )
  }

  /**
   * muestra las alertas de mensajes al usuario
   *
   * @param String mensaje para mostrar
   * @param Integer tiempo en milisegundos que se va a mostrar el mensaje
   * @returns muestra el mensaje
   */
  mostrarAlerta( mensaje, tiempo ){
    this.snackBar.open( mensaje, 'Aceptar', {
      duration: tiempo,
    });
  }
}
