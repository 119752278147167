import { Component, OnInit, Inject,ViewChild } from '@angular/core';
import { DOCUMENT, Location, formatDate } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BrowserModule, DomSanitizer,  Meta, Title } from '@angular/platform-browser';
import { NgbModal, ModalDismissReasons, NgbModule, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { MatSnackBar, MatDialog } from '@angular/material';
import { ConfirmAlertComponent } from '../../../../../content/alerts/confirm-alert/confirm-alert.component';


//Providers
import { UsuarioProviderService } from '../../../../../providers/Central/usuario/usuario-provider.service';


@Component({
  selector: 'app-datos-proveedor',
  templateUrl: './datos-proveedor.component.html',
  styleUrls: ['./datos-proveedor.component.css']
})
export class DatosProveedorComponent implements OnInit {
  private token: any;
  private idProveedor: any;
  private tipoUsuario: any = "";
  private sesion: any;
  public proveedor: any = {};
  public contactoNuevo: any = {};

  constructor( private router: Router,  public snackBar: MatSnackBar, private usuarioProvider: UsuarioProviderService,  public dialog: MatDialog, private modalService: NgbModal ) { }


  ngOnInit() {
    this.token = this.obtenerToken();
    if( this.token != false ){
      let datos = {
        'idUsuario': this.idProveedor,
        'tipo':      this.tipoUsuario
      }
      this.cargarDatosUsuario( this.token, datos );
    }
  }

  /**
  * Obtiene el token almacenado en el localStorage
  *
  * @returns String con la cadena del token o Booleano si no hay sesion abierta
  */
  obtenerToken(){
    let salida: any;
    let datos =  JSON.parse( localStorage.getItem( 'sesion' ) );
    if( datos != null ){
      if( datos.sesion == 0 ){
        salida = false;
      }else{
        this.idProveedor = datos.idUsuario;
        this.tipoUsuario = datos.tipoUsuario;
        salida = datos.token;
      }
    }else{
      salida = false;
    }
    return salida;
  }

  /**
  * Carga los datos del usuario
  *
  * @param String token almacenado
  * @param Object datos del usuario( id del usuario y tipo )
  * @returns respuesta del servdor
  */
  cargarDatosUsuario( token: any, datosUsuario: any ){
    this.usuarioProvider.consultarDatosCompletosUsuario( token, datosUsuario ).subscribe(
      ( data ) => {
        let datos = data.datos;
        let that  = this;
        that.proveedor = datos;
        that.proveedor.tipoUsuario = this.tipoUsuario;
        if( datos.fechaDiligenciamiento == "" ){
          let today = new Date();
          that.proveedor.fechaDiligenciamiento = today.toLocaleDateString("en-US");
        }
      },
      ( error ) => {
        if( error.status == 401){
          this.mostrarAlerta( "La sesión ha expirado", 4000 );
          this.logOut();
        }
        if( error.status == 400 || error.status == 500 ){
          this.mostrarAlerta( error.error.status, 4000 );
        }
      }
    );
  }

  /**
  * Envia la solicitud a la api para actualizar los datos del proveedor
  *
  * @returns respuesta del servidor
  */
  guardarDatos(){
    let datos = {
      'idProveedor':  this.idProveedor,
      'tipoUsuario':         this.tipoUsuario,
      'datos':        this.proveedor
    }
    this.usuarioProvider.actualizarInformacionUsuario( this.token, datos ).subscribe(
      ( data )  =>  {
        this.mostrarAlerta( "Los datos se actualizaron correctamente", 4000 );
      },
      ( error ) =>  {
        if( error.status == 401){
          this.mostrarAlerta( "La sesión ha expirado", 4000 );
          this.logOut();
        }
        if( error.status == 400 || error.status == 500 ){
          this.mostrarAlerta( error.error.status, 4000 );
        }
      }
    );
  }

  /**
  * Agrega el contacto a la lista
  *
  */
  agregarContacto(  ) {
    let datos = {
      'idContacto':     0,
      'nombre':         this.contactoNuevo.nombre,
      'telefono':       this.contactoNuevo.telefono,
      'area':           this.contactoNuevo.area
    };
    this.proveedor.contactos.push( datos );
    this.contactoNuevo = {};
  }

  /**
  * Abre el modal de a cuerdo al id
  *
  * @param String id del modal que se va ha abrir
  *
  * @returns Abre modal
  */
  abrirModal( content ) {
    this.modalService.open(content, { windowClass: 'dark-modal-profesionales' });
  }

  /**
  * Realiza el cierre de sesion
  *
  * @returns hace el cierre de sesion y redirecciona al login
  */
  logOut(){
    let datos = {
      sesion: 0
    }
    localStorage.setItem( 'sesion', JSON.stringify( datos ) );
    this.sesion = false;
    location.href='/inicio';
  }

  /* muestra las alertas de mensajes al usuario
  *
  * @param String mensaje para mostrar
  * @param Integer tiempo en milisegundos que se va a mostrar el mensaje
  * @returns muestra el mensaje
  */
  mostrarAlerta( mensaje, tiempo ){
    this.snackBar.open( mensaje, 'Aceptar', {
      duration: tiempo,
    });
  }
}
