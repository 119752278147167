import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar, MatDialog } from '@angular/material';
import { HomeComponent } from './content/home/home.component';

//Providers
import { UsuarioProviderService } from './providers/Central/usuario/usuario-provider.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';
  events: string[] = [];
  opened: boolean;
  active: any = false;
  public isCollapsed = true;
  public token: any;
  public idUsuario: any;
  private tipoUsuario: any;
  public busqueda: any = "";
  public sesion: any = false;
  public usuario: any = {};

  constructor( private router: Router, private activateRoute: ActivatedRoute, public snackBar: MatSnackBar, private usuarioProvider: UsuarioProviderService ){
  }

  ngOnInit() {
    this.token = this.obtenerToken();
    if( this.token != false ){
      let datos = {
        'idUsuario': this.idUsuario,
        'tipo':      this.tipoUsuario
      }
      this.cargarDatosUsuario( this.token, datos );
    }
  }

  /**
  * Obtiene el token almacenado en el localStorage
  *
  * @returns String con la cadena del token o Booleano si no hay sesion abierta
  */
  obtenerToken(){
    let salida: any;
    let datos =  JSON.parse( localStorage.getItem( 'sesion' ) );
    if( datos != null ){
      if( datos.sesion == 0 ){
        salida = false;
      }else{
        this.idUsuario = datos.idUsuario;
        this.tipoUsuario = datos.tipoUsuario;
        salida = datos.token;
      }
    }else{
      salida = false;
    }
    return salida;
  }

  /**
  * realiza la validacion de una busqueda y redireccina para ver los resultados
  *
  */
  buscar(){
    let validacion = this.busqueda.replace(/ /g, "");
    if( validacion != "" ){
        let palabraBuscar = this.busqueda.replace(/ /g, "_");
        this.router.navigate(['/buscar', palabraBuscar ]);
    }
  }

  activarMenu(){
    this.active = !this.active;
  }

  /* Carga los datos del usuario
  *
  * @param String token almacenado
  * @param Integer id del usuario logeado
  * @returns respuesta del servdor
  */
  cargarDatosUsuario( token: any, idUsuario: any ){
    this.usuarioProvider.consultarUsuario( token, idUsuario).subscribe(
      ( data ) => {
        let datos = data.datos;
        let that  = this;
        that.sesion = true;
        that.usuario = datos;
      },
      ( error ) => {
        if( error.status == 401){
          this.mostrarAlerta( "La sesión ha expirado", 4000 );
          this.logOut();
        }
        if( error.status == 400 || error.status == 500 ){
          this.mostrarAlerta( error.error.status , 4000 );
        }
      }
    );
  }

  irAlPerfil(){
    if( this.tipoUsuario == 1 ){
      this.router.navigate(['/perfilEmpleado' ]);
    }
    if( this.tipoUsuario == 2 ){
      this.router.navigate(['/perfilAspirante' ]);
    }
    if( this.tipoUsuario == 3 ){
      this.router.navigate(['/perfilProveedor' ]);
    }
  }
  /**
  * Realiza el cierre de sesion
  *
  * @returns hace el cierre de sesion y redirecciona al login
  */
  logOut(){
    let datos = {
      sesion: 0
    }
    localStorage.setItem( 'sesion', JSON.stringify( datos ) );
    this.sesion = false;
    location.href='/inicio';
  }

  /* muestra las alertas de mensajes al usuario
  *
  * @param String mensaje para mostrar
  * @param Integer tiempo en milisegundos que se va a mostrar el mensaje
  * @returns muestra el mensaje
  */
  mostrarAlerta( mensaje, tiempo ){
    this.snackBar.open( mensaje, 'Aceptar', {
      duration: tiempo,
    });
  }
}
