import { Component, OnInit, Inject,ViewChild } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BrowserModule, DomSanitizer,  Meta, Title } from '@angular/platform-browser';
import { NgbModule, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar, MatDialog } from '@angular/material';

//Providers
import { UsuarioProviderService } from '../../../providers/Central/usuario/usuario-provider.service';


@Component({
  selector: 'app-ofertas-laborales',
  templateUrl: './ofertas-laborales.component.html',
  styleUrls: ['./ofertas-laborales.component.css']
})
export class OfertasLaboralesComponent implements OnInit {

  public ofertas: any = [];
  private idUsuario: any = "";
  private tipoUsuario: any = "";
  private sesion: any;
  private token: any = "";

  constructor( private router: Router,  public snackBar: MatSnackBar, private usuarioProvider: UsuarioProviderService ) {
  }

  ngOnInit() {
    this.token = this.obtenerToken();
    this.cargarOfertasLaborales();
  }

  /**
  * Obtiene el token almacenado en el localStorage
  *
  * @returns String con la cadena del token o Booleano si no hay sesion abierta
  */
  obtenerToken(){
    let salida: any;
    let datos =  JSON.parse( localStorage.getItem( 'sesion' ) );
    if( datos != null ){
      if( datos.sesion == 0 ){
        salida = false;
      }else{
        this.idUsuario = datos.idUsuario;
        this.tipoUsuario = datos.tipoUsuario;
        salida = datos.token;
      }
    }else{
      salida = false;
    }
    return salida;
  }

  /**
  * Envia la solicitud a la api para traer los datos de las ofertas laborales
  *
  * @param Object datos del usuario
  * @returns array respuesta del servidor
  */
  cargarOfertasLaborales(){
    let id = 0;
    if( this.tipoUsuario == 2 ){
      id = this.idUsuario;
    }
    let datos = {
      'idUsuario':  id,
      'tipo':       this.tipoUsuario
    };
    this.usuarioProvider.consultarOfertasLaborales( datos ).subscribe(
      ( data ) => {
        let datos = data.datos;
        let that = this;
        that.ofertas = datos;
      },
      ( error ) => {
        if( error.status == 401){
          this.mostrarAlerta( "La sesión ha expirado", 4000 );
          this.logOut();
        }
        if( error.status == 400 || error.status == 500 ){
          this.mostrarAlerta( error.error.status, 4000 );
        }
      }
    );

  }

  aplicarOferta( idOferta ){
    let datos = {
      'idOfertaEmpleo': idOferta,
      'idAspirante':    this.idUsuario
    };
    this.usuarioProvider.aplicarOfertaLaboral( this.token, datos ).subscribe(
      ( data ) => {
        let datos = data.datos;
        let that = this;
        that.mostrarAlerta( "La solicitud se envio exitosamente", 5000);
        that.cargarOfertasLaborales();
      },
      ( error ) => {
        if( error.status == 400 || error.status == 500 ){
          this.mostrarAlerta( error.error.status, 4000 );
        }
      }
    );
  }

  /**
  * Realiza el cierre de sesion
  *
  * @returns hace el cierre de sesion y redirecciona al login
  */
  logOut(){
    let datos = {
      sesion: 0
    }
    localStorage.setItem( 'sesion', JSON.stringify( datos ) );
    this.sesion = false;
    location.href='/inicio';
  }

  /* muestra las alertas de mensajes al usuario
  *
  * @param String mensaje para mostrar
  * @param Integer tiempo en milisegundos que se va a mostrar el mensaje
  * @returns muestra el mensaje
  */
  mostrarAlerta( mensaje, tiempo ){
    this.snackBar.open( mensaje, 'Aceptar', {
      duration: tiempo,
    });
  }
}
