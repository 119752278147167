import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { ENV } from '../../../../conf/ENV';
import { ResponseInformationObjectInterface } from '../../../wrappers/response-information-object-interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsuarioProviderService {

  public environment: any;
  public configUrl: any;

  constructor( private http: HttpClient, private env: ENV ) {
    this.environment = env.getEnv();
    this.configUrl = this.environment.API_URL + "/usuario/";
  }

  /**
  * Envia la solicitud a la Api para consultar los datos basicos del usuario
  *
  * @param String token almacenados
  * @param Object datos del usuario ( idUsuario, tipos usuario )
  *
  * @returns respuesta del servidor o mensaje de error
  */
  consultarUsuario( token: any, datos: any ){
    let accion = 'consultarUsuario/';
    let headers = new HttpHeaders({
     'content-type': 'application/json',
     'Authorization': 'Bearer '+token,
    });
    var options =  {
       headers: headers
    };
    return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
  }

  /**
  * Envia la solicitud a la Api para consultar los datos completos del usuario
  *
  * @param String token almacenados
  * @param Object datos del usuario ( Usuario, contraseña )
  *
  * @returns respuesta del servidor o mensaje de error
  */
  consultarDatosCompletosUsuario( token: any, datos: any ){
    let accion = 'consultarDatosCompletosUsuario/';
    let headers = new HttpHeaders({
     'content-type': 'application/json',
     'Authorization': 'Bearer '+token,
    });
    var options =  {
       headers: headers
    };
    return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
  }

  /**
  * Envia la solicitud a la Api para actualizar los datos del usuario
  *
  * @param String token almacenados
  * @param Object datos del usuario ( Usuario, contraseña )
  *
  * @returns respuesta del servidor o mensaje de error
  */
  actualizarInformacionUsuario( token: any, datos: any ){
    let accion = 'actualizarInformacionUsuario/';
    let headers = new HttpHeaders({
     'content-type': 'application/json',
     'Authorization': 'Bearer '+token,
    });
    var options =  {
       headers: headers
    };
    return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
  }

  /**
  * Envia la solicitud a la Api para eliminar el telefono del empleado
  *
  * @param String token almacenado
  * @param Integer id del telefono del empleado
  * @returns respuesta del servidor o mensaje de error
  */
  eliminarTelefonoEmpleado( token: any, idTelefono: any ){
    let accion = 'eliminarTelefonoEmpleado/'+idTelefono;
    let headers = new HttpHeaders({
     'content-type': 'application/json',
     'Authorization': 'Bearer '+token,
    });
    var options =  {
       headers: headers
    };

    return this.http.delete<ResponseInformationObjectInterface>( this.configUrl+accion, options );
  }

  /**
  * Envia la solicitud a la Api para eliminar el contacto del empleado
  *
  * @param String token almacenado
  * @param Integer id del contacto del empleado
  * @returns respuesta del servidor o mensaje de error
  */
  eliminarContactoEmpleado( token: any, idContacto: any ){
    let accion = 'eliminarContactoEmpleado/'+idContacto;
    let headers = new HttpHeaders({
      'content-type': 'application/json',
      'Authorization': 'Bearer '+token,
    });
    var options =  {
        headers: headers
    };

    return this.http.delete<ResponseInformationObjectInterface>( this.configUrl+accion, options );
  }

  /**
  * Realiza la peticion a la api para actualizar la foto del aspirante
  *
  * @param String token
  * @param object informacion de la imagen del aspirante
  * @returns respuesta del servidor o mensaje de error
  */
  actualizarFotoAsirante( token: any, data: any){
   return Observable.create( (observer) => {
       let xhr = new XMLHttpRequest();
       let accion = "guardarFotoAspirante/";

       xhr.onreadystatechange = function () {
         if (xhr.readyState == 4) {
           if (xhr.status == 200) {
             observer.next( JSON.parse( xhr.response ) );
             observer.complete();
           } else {
             observer.error(xhr.response);
           }
         }
       };

       xhr.open("POST", this.configUrl + accion, true);
       xhr.setRequestHeader("Authorization", 'Bearer '+token);
       xhr.send(data);
     }  );
  }

  /**
  * Envia la solicitud a la Api para consultar las certificaciones de estudio del aspirante
  *
  * @param String token almacenados
  * @param Object datos del usuario ( idAspirante, tipoUsuario )
  *
  * @returns respuesta del servidor o mensaje de error
  */
  consultarCertificacionesEstudioAspirante( token: any, datos: any ){
    let accion = 'consultarCertificacionesEstudioAspirante/';
    let headers = new HttpHeaders({
     'content-type': 'application/json',
     'Authorization': 'Bearer '+token,
    });
    var options =  {
       headers: headers
    };
    return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
  }

  /**
  * Envia la solicitud a la Api para actualizar los datos de las certificaciones de estudios del aspirante
  *
  * @param String token almacenados
  * @param Object datos del usuario ( idAspirante, tipoUsuario, certificaciones )
  *
  * @returns respuesta del servidor o mensaje de error
  */
  actualizarCertificacionesEstudioAspirante( token: any, datos: any ){
    let accion = 'actualizarCertificacionesEstudioAspirante/';
    let headers = new HttpHeaders({
     'content-type': 'application/json',
     'Authorization': 'Bearer '+token,
    });
    var options =  {
       headers: headers
    };
    return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
  }

  /**
  * Envia la solicitud a la Api para eliminar el certificado de estudio del aspirante
  *
  * @param String token almacenado
  * @param Integer id del certificado de estudio del aspirante
  * @returns respuesta del servidor o mensaje de error
  */
  eliminarCertificadoEstudioAspirante( token: any, idCertificado: any ){
    let accion = 'eliminarCertificadoEstudioAspirante/'+idCertificado;
    let headers = new HttpHeaders({
     'content-type': 'application/json',
     'Authorization': 'Bearer '+token,
    });
    var options =  {
       headers: headers
    };

    return this.http.delete<ResponseInformationObjectInterface>( this.configUrl+accion, options );
  }

  /**
  * Envia la solicitud a la Api para consultar los documentos adicionales del aspirante
  *
  * @param String token almacenados
  * @param Object datos del usuario ( idAspirante, tipoUsuario )
  *
  * @returns respuesta del servidor o mensaje de error
  */
  consultarDocumentosAspirante( token: any, datos: any ){
    let accion = 'consultarDocumentosAspirante/';
    let headers = new HttpHeaders({
     'content-type': 'application/json',
     'Authorization': 'Bearer '+token,
    });
    var options =  {
       headers: headers
    };
    return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
  }

  /**
  * Realiza la peticion para subir los archivos del aspirante
  *
  * @param String token
  * @param object informacion del archivo del aspirante
  * @returns respuesta del servidor o mensaje de error
  */
  subirArchivoAspirante( token: any, data: any){
   return Observable.create( (observer) => {
       let xhr = new XMLHttpRequest();
       let accion = "guardarArchivoAspirante/";

       xhr.onreadystatechange = function () {
         if (xhr.readyState == 4) {
           if (xhr.status == 200) {
             observer.next( JSON.parse( xhr.response ) );
             observer.complete();
           } else {
             observer.error(xhr.response);
           }
         }
       };

       xhr.open("POST", this.configUrl + accion, true);
       xhr.setRequestHeader("Authorization", 'Bearer '+token);
       xhr.send(data);
     }  );
  }

  /**
  * Realiza la peticion para subir la hoja de vida del aspirante
  *
  * @param String token
  * @param object informacion del archivo del aspirante
  * @returns respuesta del servidor o mensaje de error
  */
  subirHojaVidaAspirante( token: any, data: any){
   return Observable.create( (observer) => {
       let xhr = new XMLHttpRequest();
       let accion = "subirHojaVidaAspirante/";

       xhr.onreadystatechange = function () {
         if (xhr.readyState == 4) {
           if (xhr.status == 200) {
             observer.next( JSON.parse( xhr.response ) );
             observer.complete();
           } else {
             observer.error(xhr.response);
           }
         }
       };

       xhr.open("POST", this.configUrl + accion, true);
       xhr.setRequestHeader("Authorization", 'Bearer '+token);
       xhr.send(data);
     }  );
  }

  /**
  * Realiza la peticion para consultar las ofertas laborales
  *
  * @param object informacion del aspirante
  * @returns respuesta del servidor o mensaje de error
  */
  consultarOfertasLaborales( datos: any ) {
    let accion = 'consultarOfertasLaborales/';
    let headers = new HttpHeaders({
     'content-type': 'application/json'
    });
    var options =  {
       headers: headers
    };
    return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
  }

  /**
  * Envia la solicitud a la Api para aplicar que el aspirante aplique a la oferta laboral
  *
  * @param String token almacenados
  * @param Object datos ( idUsuario, idOferta )
  *
  * @returns respuesta del servidor o mensaje de error
  */
  aplicarOfertaLaboral( token: any, datos: any ){
    let accion = 'aplicarOfertaLaboral/';
    let headers = new HttpHeaders({
     'content-type': 'application/json',
     'Authorization': 'Bearer '+token,
    });
    var options =  {
       headers: headers
    };
    return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
  }

  /**
  * Envia la solicitud a la Api consultar las ofertas laborales a las que aplico el aspirante
  *
  * @param String token almacenados
  * @param Object datos ( idUsuario )
  *
  * @returns respuesta del servidor o mensaje de error
  */
  consultarOfertasLaboralesAspirante( token: any, datos: any ){
    let accion = 'consultarOfertasLaboralesAspirante/';
    let headers = new HttpHeaders({
     'content-type': 'application/json',
     'Authorization': 'Bearer '+token,
    });
    var options =  {
       headers: headers
    };
    return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
  }

  /**
  * Envia la solicitud a la Api para eliminar la oferta laboral de la lista del aspirante
  *
  * @param String token almacenado
  * @param Integer id de la aplicacion de la oferta laboral
  * @returns respuesta del servidor o mensaje de error
  */
  eliminarOfertaLaboralAspirante( token: any, idAplicaOferta: any ){
    let accion = 'eliminarOfertaLaboralAspirante/'+idAplicaOferta;
    let headers = new HttpHeaders({
     'content-type': 'application/json',
     'Authorization': 'Bearer '+token,
    });
    var options =  {
       headers: headers
    };

    return this.http.delete<ResponseInformationObjectInterface>( this.configUrl+accion, options );
  }

  /**
  * Envia la solicitud a la Api consultar los regimenes de iva y naturaleza juridica
  *
  * @param String token almacenados
  * @param Object datos ( idUsuario )
  *
  * @returns respuesta del servidor o mensaje de error
  */
  consultarNaturalezaRegimen( token: any, datos: any ){
    let accion = 'consultarNaturalezaRegimen/';
    let headers = new HttpHeaders({
     'content-type': 'application/json',
     'Authorization': 'Bearer '+token,
    });
    var options =  {
       headers: headers
    };
    return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
  }

  /**
  * Envia la solicitud a la Api para actualizar la informacion tributaria
  *
  * @param String token almacenados
  * @param Object datos ( datos tributarios )
  *
  * @returns respuesta del servidor o mensaje de error
  */
  actualizarInformacionTributaria( token: any, datos: any ){
    let accion = 'actualizarInformacionTributaria/';
    let headers = new HttpHeaders({
     'content-type': 'application/json',
     'Authorization': 'Bearer '+token,
    });
    var options =  {
       headers: headers
    };
    return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
  }

  /**
  * Envia la solicitud a la Api para consultar  la informacion tributaria
  *
  * @param String token almacenados
  * @param Object datos ( id y tipo de usuario )
  *
  * @returns respuesta del servidor o mensaje de error
  */
  consultarInformacionTributariaProveedor( token: any, datos: any ){
    let accion = 'consultarInformacionTributariaProveedor/';
    let headers = new HttpHeaders({
     'content-type': 'application/json',
     'Authorization': 'Bearer '+token,
    });
    var options =  {
       headers: headers
    };
    return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
  }

  /**
  * Envia la solicitud a la Api para actualizar la informacion financiera
  *
  * @param String token almacenados
  * @param Object datos ( datos tributarios )
  *
  * @returns respuesta del servidor o mensaje de error
  */
  actualizarInformacionFinanciera( token: any, datos: any ){
    let accion = 'actualizarInformacionFinanciera/';
    let headers = new HttpHeaders({
     'content-type': 'application/json',
     'Authorization': 'Bearer '+token,
    });
    var options =  {
       headers: headers
    };
    return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
  }

  /**
  * Envia la solicitud a la Api para consultar  la informacion financiera
  *
  * @param String token almacenados
  * @param Object datos ( id y tipo de usuario )
  *
  * @returns respuesta del servidor o mensaje de error
  */
  consultarInformacionFinancieraProveedor( token: any, datos: any ){
    let accion = 'consultarInformacionFinancieraProveedor/';
    let headers = new HttpHeaders({
     'content-type': 'application/json',
     'Authorization': 'Bearer '+token,
    });
    var options =  {
       headers: headers
    };
    return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
  }

  /**
  * Envia la solicitud a la Api para actualizar la informacion financiera
  *
  * @param String token almacenados
  * @param Object datos ( datos tributarios )
  *
  * @returns respuesta del servidor o mensaje de error
  */
  actualizarReferenciasComerciales( token: any, datos: any ){
    let accion = 'actualizarReferenciasComerciales/';
    let headers = new HttpHeaders({
     'content-type': 'application/json',
     'Authorization': 'Bearer '+token,
    });
    var options =  {
       headers: headers
    };
    return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
  }

  /**
  * Envia la solicitud a la Api para consultar  la informacion financiera
  *
  * @param String token almacenados
  * @param Object datos ( id y tipo de usuario )
  *
  * @returns respuesta del servidor o mensaje de error
  */
  consultarInformacionReferenciasComerciales( token: any, datos: any ){
    let accion = 'consultarReferenciasComerciales/';
    let headers = new HttpHeaders({
     'content-type': 'application/json',
     'Authorization': 'Bearer '+token,
    });
    var options =  {
       headers: headers
    };
    return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
  }

  /**
  * Envia la solicitud a la Api para consultar los documentos adicionales del proveedor
  *
  * @param String token almacenados
  * @param Object datos del usuario ( idAspirante, tipoUsuario )
  *
  * @returns respuesta del servidor o mensaje de error
  */
  consultarDocumentosProveedor( token: any, datos: any ){
    let accion = 'consultarDocumentosProveedor/';
    let headers = new HttpHeaders({
     'content-type': 'application/json',
     'Authorization': 'Bearer '+token,
    });
    var options =  {
       headers: headers
    };
    return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
  }

  /**
  * Realiza la peticion para subir los archivos del aspirante
  *
  * @param String token
  * @param object informacion del archivo del aspirante
  * @returns respuesta del servidor o mensaje de error
  */
  subirArchivoProveedor( token: any, data: any){
   return Observable.create( (observer) => {
       let xhr = new XMLHttpRequest();
       let accion = "guardarArchivoProveedor/";

       xhr.onreadystatechange = function () {
         if (xhr.readyState == 4) {
           if (xhr.status == 200) {
             observer.next( JSON.parse( xhr.response ) );
             observer.complete();
           } else {
             observer.error(xhr.response);
           }
         }
       };

       xhr.open("POST", this.configUrl + accion, true);
       xhr.setRequestHeader("Authorization", 'Bearer '+token);
       xhr.send(data);
     }  );
  }
}
