import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { SidebarModule } from 'ng-sidebar';

import {
    MatAutocompleteModule, MatBadgeModule, MatBottomSheetModule, MatButtonModule, MatButtonToggleModule,
    MatCardModule, MatCheckboxModule, MatChipsModule, MatDatepickerModule, MatDialogModule, MatDividerModule,
    MatExpansionModule, MatGridListModule, MatIconModule, MatInputModule, MatListModule, MatMenuModule, MatNativeDateModule,
    MatPaginatorModule, MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule,   MatRippleModule, MatSelectModule,
    MatSidenavModule, MatSliderModule, MatSlideToggleModule, MatSnackBarModule, MatSortModule, MatStepperModule, MatTableModule,
    MatTabsModule, MatToolbarModule, MatTooltipModule,   MatTreeModule,
} from '@angular/material';
import { AgmCoreModule } from '@agm/core';
import { OwlModule } from 'ngx-owl-carousel';

//Providers
import { MenuPrincipalProviderService } from './providers/menus/menuPrincipal/menu-principal-provider.service';
import { MenuFootProviderService } from './providers/menus/menuFoot/menu-foot-provider.service';
import { PaginaProviderService } from './providers/paginas/paginas/pagina-provider.service';
import { CorreoProviderService } from './providers/correos/correo-provider.service';
import { BusquedaProviderService } from './providers/busqueda/busqueda-provider.service';
//CENTRAL
import { CapacitacionesProviderService } from './providers/Central/capacitaciones/capacitaciones-provider.service';
import { LoginRegistroProviderService } from './providers/Central/loginRegistro/login-registro-provider.service';
import { UsuarioProviderService } from './providers/Central/usuario/usuario-provider.service';

// Environment configuration
import { ENV } from '../conf/ENV';

//Paginas
import { AppComponent } from './app.component';
import { NavbarComponent } from './content/navbar/navbar.component';
import { HomeComponent } from './content/home/home.component';
import { SectionComponent } from './content/section/section.component';
import { FootComponent } from './content/foot/foot.component';
import { ConvenioComponent } from './content/convenio/convenio.component';
import { SugerenciasComponent } from './content/sugerencias/sugerencias.component';
import { NoticiasPortalComponent } from './content/noticias-portal/noticias-portal.component';
import { ContactoComponent } from './content/contacto/contacto.component';
import { BusquedaComponent } from './content/busqueda/busqueda.component';
//CENTRAL
import { LoginEmpleadoComponent } from './content/Central/login/login-empleado/login-empleado.component';
import { LoginProveedorComponent } from './content/Central/login/login-proveedor/login-proveedor.component';
import { LoginAspiranteComponent } from './content/Central/login/login-aspirante/login-aspirante.component';
import { PerfilEmpleadoComponent } from './content/Central/perfiles/perfil-empleado/perfil-empleado.component';
import { InformacionEmpleadoComponent } from './content/Central/perfiles/perfil-empleado/informacion-empleado/informacion-empleado.component';
import { ConfirmAlertComponent } from './content/alerts/confirm-alert/confirm-alert.component';
import { InformacionCapacitacionesComponent } from './content/Central/perfiles/perfil-empleado/informacion-capacitaciones/informacion-capacitaciones.component';
import { CapacitacionComponent } from './content/Central/capacitaciones/capacitacion/capacitacion.component';
import { PerfilAspiranteComponent } from './content/Central/perfiles/perfil-aspirante/perfil-aspirante.component';
import { InformacionAspiranteComponent } from './content/Central/perfiles/perfil-aspirante/informacion-aspirante/informacion-aspirante.component';
import { EstudiosAspiranteComponent } from './content/Central/perfiles/perfil-aspirante/estudios-aspirante/estudios-aspirante.component';
import { DocumentosAspiranteComponent } from './content/Central/perfiles/perfil-aspirante/documentos-aspirante/documentos-aspirante.component';
import { AplicacionOfertasComponent } from './content/Central/perfiles/perfil-aspirante/aplicacion-ofertas/aplicacion-ofertas.component';
import { OfertasLaboralesComponent } from './content/Central/ofertas-laborales/ofertas-laborales.component';
import { PerfilProveedorComponent } from './content/Central/perfiles/perfil-proveedor/perfil-proveedor.component';
import { DatosProveedorComponent } from './content/Central/perfiles/perfil-proveedor/datos-proveedor/datos-proveedor.component';
import { InformacionTributariaComponent } from './content/Central/perfiles/perfil-proveedor/informacion-tributaria/informacion-tributaria.component';
import { InformacionFinancieraComponent } from './content/Central/perfiles/perfil-proveedor/informacion-financiera/informacion-financiera.component';
import { ReferenciasComercialesComponent } from './content/Central/perfiles/perfil-proveedor/referencias-comerciales/referencias-comerciales.component';
import { DocumentosProveedorComponent } from './content/Central/perfiles/perfil-proveedor/documentos-proveedor/documentos-proveedor.component';



//Routing
const appRoutes: Routes = [
    {   path: '', component: HomeComponent },
    {   path: 'inicio', component: HomeComponent },
    {   path: 'web/:url', component: SectionComponent },
    {   path: 'buscar/:busqueda', component: BusquedaComponent },
    {   path: 'convenio', component: ConvenioComponent },
    {   path: 'contacto', component: ContactoComponent },
    {   path: 'sugerencias', component: SugerenciasComponent },
    {   path: 'blog', component: NoticiasPortalComponent },
    {   path: 'blog/:url', component: NoticiasPortalComponent },
    //CENTRAL
    {   path: 'capacitacion/:id', component: CapacitacionComponent },
    {   path: 'loginEmpleado', component: LoginEmpleadoComponent },
    {   path: 'loginProveedor', component: LoginProveedorComponent },
    {   path: 'loginAspirante', component: LoginAspiranteComponent },
    {   path: 'perfilEmpleado', component: PerfilEmpleadoComponent },
    {   path: 'perfilAspirante', component: PerfilAspiranteComponent },
    {   path: 'ofertasLaborales', component: OfertasLaboralesComponent },
    {   path: 'perfilProveedor', component: PerfilProveedorComponent },

];
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    SectionComponent,
    FootComponent,
    ConvenioComponent,
    SugerenciasComponent,
    NoticiasPortalComponent,
    ContactoComponent,
    BusquedaComponent,
    //CENTRAL
    LoginEmpleadoComponent,
    LoginProveedorComponent,
    LoginAspiranteComponent,
    PerfilEmpleadoComponent,
    InformacionEmpleadoComponent,
    ConfirmAlertComponent,
    InformacionCapacitacionesComponent,
    CapacitacionComponent,
    PerfilAspiranteComponent,
    InformacionAspiranteComponent,
    EstudiosAspiranteComponent,
    DocumentosAspiranteComponent,
    AplicacionOfertasComponent,
    OfertasLaboralesComponent,
    PerfilProveedorComponent,
    DatosProveedorComponent,
    InformacionTributariaComponent,
    InformacionFinancieraComponent,
    ReferenciasComercialesComponent,
    DocumentosProveedorComponent
  ],
  imports: [
    BrowserModule,
    MDBBootstrapModule.forRoot(),
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    NgbModule.forRoot(),
    RouterModule.forRoot(appRoutes),
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCWsYWaWBpm2OuvNq3CnKVMC72tATLUbkg'
    }),
    OwlModule,
    FormsModule,
    SidebarModule.forRoot()
  ],
  entryComponents: [
      ConfirmAlertComponent
  ],
  providers: [
      ENV,
      MenuPrincipalProviderService,
      PaginaProviderService,
      MenuFootProviderService,
      CorreoProviderService,
      BusquedaProviderService,
      //CENTRAL
      CapacitacionesProviderService,
      LoginRegistroProviderService,
      UsuarioProviderService
  ],
  schemas: [ NO_ERRORS_SCHEMA ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
