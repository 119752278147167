import { Component, OnInit, Inject,ViewChild } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BrowserModule, DomSanitizer,  Meta, Title } from '@angular/platform-browser';
import { NgbModule, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { MatSnackBar, MatDialog } from '@angular/material';
import { Md5 } from 'ts-md5/dist/md5';

//provider
import { LoginRegistroProviderService } from '../../../../providers/Central/loginRegistro/login-registro-provider.service';

@Component({
  selector: 'app-login-aspirante',
  templateUrl: './login-aspirante.component.html',
  styleUrls: ['./login-aspirante.component.css']
})
export class LoginAspiranteComponent implements OnInit {

  public usuario: any = "";
  public contrasena: any = "";
  public registro: any = false;
  public contrasena1: any = "";
  public contrasena2: any = "";
  public mensaje: any = "";
  public vistaMensaje: any = false;
  public registroExitoso: any = false;


  private token: any = "";

  constructor( private router: Router, private meta: Meta, private title: Title, @Inject(DOCUMENT) private document: any, private location: Location, public snackBar: MatSnackBar, private loginProvider: LoginRegistroProviderService ) { }

  ngOnInit() {
    this.token = this.obtenerToken();
    if( this.token != false ){
      location.href='/perfilAspirante';
    }
  }

  /**
  * Realiza el cambio al formulario de registro
  *
  */
  mostrarFormularioRegistro(){
    this.vistaMensaje = false;
    this.mensaje = "";
    this.registro = true;
  }

  /**
  * Realiza el cambio al formulario de inicio de sesion
  *
  */
  mostrarFormularioLogin(){
    this.vistaMensaje = false;
    this.mensaje = "";
    this.registro = false;
  }

  iniciarSesion(){
    this.vistaMensaje = false;
    this.mensaje = "";
    let datos = {
      'usuario':  this.usuario,
      'contrasena': Md5.hashStr( this.contrasena ),
      'tipo': 2
    };
    this.loginProvider.iniciarSesionAspirante( datos ).subscribe(
      ( data ) => {
        let datos = data.datos;
        let that = this;
        that.cargarLocalStorage( datos );
        location.href='/perfilAspirante';
      },
      ( error ) => {
        if( error.status == 500 || error.status == 400 ){
          this.mensaje = error.error.status;
          this.vistaMensaje = true;
          this.mostrarAlerta( error.error.status, 4000);
        }
      }
    );
  }
  /**
  * Envia la solicitud a la api para el registro de usuario
  *
  */
  registrarUsuario(){
    this.vistaMensaje = false;
    this.mensaje = "";
    this.registroExitoso = false;
    let validacion = this.validarInformacion();
    if( validacion ){
      let datos = {
        'usuario':  this.usuario,
        'contrasena': Md5.hashStr( this.contrasena2 )
      }
      this.loginProvider.registroAspirante( datos ).subscribe(
        ( data ) => {
          let datos = data.datos;
          let that = this;
          that.cargarLocalStorage( datos );
          that.vistaMensaje = true;
          that.mensaje = "El registro se realizo exitosamente, ahora debe completar los datos faltantes.";
          that.registroExitoso = true;
        },
        ( error ) => {
          if( error.status == 500 || error.status == 400 ){
            this.mensaje = error.error.Error;
            this.vistaMensaje = true;
            this.mostrarAlerta( error.error.Error, 4000);
          }
        }
      );
    }
  }

  /**
  * Realiza la valiacion de la informacion para el registro del usuario
  *
  */
  validarInformacion(){
    let salida = true;
    if ( this.contrasena1 != this.contrasena2 ){
      this.mensaje = "Las contraseñas no concuerdan.";
      this.vistaMensaje = true;
      salida = false;
    }
    if ( ! /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(this.usuario)){
      this.mensaje = "Direccion de correo no valido";
      this.vistaMensaje = true;
      salida = false;
    }
    return salida;
  }

  /**
  * Obtiene los datos del token almacenados
  *
  * @returns retorna el token, o un false si no hay sesion
  */
  obtenerToken(){
   let salida: any;
   let datos =  JSON.parse( localStorage.getItem( 'sesion' ) );
   if( datos.sesion == 0 ){
     salida = false;
   }else{
     salida = datos.token;
   }
   return salida;
  }

  /**
  * Guarda los datos de la sesion en el localStorage
  *
  * @returns localStorage
  */
  cargarLocalStorage( datos ){
   let datosSesion = {
     sesion: 1,
     idUsuario: datos.id,
     token: datos.token,
     tipoUsuario: datos.tipo
   }
   localStorage.setItem( 'sesion', JSON.stringify( datosSesion ) )
  }

  /**
   * muestra las alertas de mensajes al usuario
   *
   * @param String mensaje para mostrar
   * @param Integer tiempo en milisegundos que se va a mostrar el mensaje
   * @returns muestra el mensaje
   */
  mostrarAlerta( mensaje, tiempo ){
    this.snackBar.open( mensaje, 'Aceptar', {
      duration: tiempo,
    });
  }
}
