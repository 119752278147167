import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { ENV } from '../../../../conf/ENV';
import { ResponseInformationObjectInterface } from '../../../wrappers/response-information-object-interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginRegistroProviderService {

  public environment: any;
  public configUrl: any;

  constructor( private http: HttpClient, private env: ENV ) {
    this.environment = env.getEnv();
    this.configUrl = this.environment.API_URL + "/login/";
  }

  /**
   * Envia la solicitud a la Api para el inicio de sesion
   *
   * @param datos del usuario ( Usuario, contraseña )
   * @returns respuesta del servidor o mensaje de error
   */
  iniciarSesionEmpleado( datos:any ){
    let accion = 'iniciarSesionEmpleado/';
    let headers = new HttpHeaders({
      'content-type': 'application/json',
    });
    var options =  {
        headers: headers
    };
    return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
  }

  /**
   * Envia la solicitud a la Api para registro del aspirante
   *
   * @param datos del usuario ( Usuario, contraseña )
   * @returns respuesta del servidor o mensaje de error
   */
  registroAspirante( datos:any ){
    let accion = 'registroAspirante/';
    let headers = new HttpHeaders({
      'content-type': 'application/json',
    });
    var options =  {
        headers: headers
    };
    return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
  }

  /**
   * Envia la solicitud a la Api para el inicio de sesion
   *
   * @param datos del usuario ( Usuario, contraseña )
   * @returns respuesta del servidor o mensaje de error
   */
  iniciarSesionAspirante( datos:any ){
    let accion = 'iniciarSesionAspirante/';
    let headers = new HttpHeaders({
      'content-type': 'application/json',
    });
    var options =  {
        headers: headers
    };
    return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
  }

  /**
   * Envia la solicitud a la Api para registro del proveedor
   *
   * @param datos del usuario ( Usuario, contraseña )
   * @returns respuesta del servidor o mensaje de error
   */
  registroProveedor( datos:any ){
    let accion = 'registroProveedor/';
    let headers = new HttpHeaders({
      'content-type': 'application/json',
    });
    var options =  {
        headers: headers
    };
    return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
  }

  /**
   * Envia la solicitud a la Api para el inicio de sesion del proveedor
   *
   * @param datos del usuario ( Usuario, contraseña )
   * @returns respuesta del servidor o mensaje de error
   */
  iniciarSesionProveedor( datos:any ){
    let accion = 'iniciarSesionProveedor/';
    let headers = new HttpHeaders({
      'content-type': 'application/json',
    });
    var options =  {
        headers: headers
    };
    return this.http.post<ResponseInformationObjectInterface>( this.configUrl+accion, datos, options );
  }

}
