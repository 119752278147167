import { Component, OnInit, Inject,ViewChild } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BrowserModule, DomSanitizer,  Meta, Title, SafeResourceUrl } from '@angular/platform-browser';
import { NgbModal, ModalDismissReasons, NgbModule, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { MatSnackBar, MatDialog } from '@angular/material';

//Providers
import { CapacitacionesProviderService } from '../../../../providers/Central/capacitaciones/capacitaciones-provider.service';


@Component({
  selector: 'app-capacitacion',
  templateUrl: './capacitacion.component.html',
  styleUrls: ['./capacitacion.component.css']
})
export class CapacitacionComponent implements OnInit {
  public currentStep = 0;
  public courseStepContent;
  public preguntas: any = [];
  private token: any = "";
  private idCapacitacion: any;
  private sesion: any;
  private capacitacion: any = {};
  public displayURL: any = "";
  public pregunta: any = {};
  private idUsuario: any = "";
  public presentacionCapacitacion: any = false;
  public mensajeCapacitacion = "";

  constructor( private activateRoute: ActivatedRoute, private router: Router,  public snackBar: MatSnackBar, private capacitacionesProvider: CapacitacionesProviderService,  public dialog: MatDialog, private modalService: NgbModal, private sanitizer: DomSanitizer ) {
    this.preguntas.length = 10;
  }

  ngOnInit() {
    this.pregunta.opcion1 = {};
    this.pregunta.opcion2 = {};
    this.pregunta.opcion3 = {};
    this.pregunta.opcion4 = {};
    this.activateRoute.paramMap.subscribe(
      ( map ) => {
        let that = this;
        that.idCapacitacion = map.get('id');
      }
    );
    this.token = this.obtenerToken();
    if( this.token != false ){
      this.cargarDatosCapacitacion( this.token, this.idCapacitacion, this.idUsuario );
    }


  }

  /**
  * Obtiene el token almacenado en el localStorage
  *
  * @returns String con la cadena del token o Booleano si no hay sesion abierta
  */
  obtenerToken(){
    let salida: any;
    let datos =  JSON.parse( localStorage.getItem( 'sesion' ) );
    if( datos != null ){
      if( datos.sesion == 0 ){
        salida = false;
      }else{
        salida = datos.token;
        this.idUsuario = datos.idUsuario;
      }
    }else{
      salida = false;
    }
    return salida;
  }

  /**
   * Seleccione la pregunta que se va a editar
   *
   * @param String   token almacenado
   * @param Integer  id de la capacitacion
   *
   * @returns respuesta del servidor
   */
  cargarDatosCapacitacion( token: any, idCapacitacion: any, idEmpleado: any ){
    this.capacitacionesProvider.consultarCapacitacion( token, idCapacitacion, idEmpleado ).subscribe(
      ( data ) => {
        let datos = data.datos;
        let that = this;
        that.capacitacion = datos;
        that.preguntas = datos.preguntas;
        that.irAPregunta( 0 );
      },
      ( error ) => {
        if( error.status == 401){
          this.mostrarAlerta( "La sesión ha expirado", 4000 );
          this.logOut();
        }
        if( error.status == 400 || error.status == 500 ){
          this.mostrarAlerta( error.error.Error, 4000 );
          this.presentacionCapacitacion = true;
          this.mensajeCapacitacion = error.error.Error;
        }
      }
    );
  }

  /**
   * Realiza la peticion para el envío del cuestionario
   *
   *
   * @returns respuesta del servidor
   */
  guardarCuestionario(){
    let datosCuestionario = {
      'idCapacitacion': this.idCapacitacion,
      'idEmpleado':     this.idUsuario,
      'preguntas':      this.preguntas

    }
    this.presentacionCapacitacion = true;
    this.mensajeCapacitacion = "Enviando";
    this.capacitacionesProvider.guardarCuestionarioCapacitacion( this.token, datosCuestionario ).subscribe(
      ( data ) => {
        let datos = data.datos;
        this.presentacionCapacitacion = true;
        this.mensajeCapacitacion = "El cuestionario fue enviado para su revisión.";
      },
      ( error ) => {
        if( error.status == 401){
          this.mostrarAlerta( "La sesión ha expirado", 4000 );
          this.logOut();
        }
        if( error.status == 400 || error.status == 500 ){
          this.mostrarAlerta( error.error.Error, 4000 );
          this.presentacionCapacitacion = true;
          this.mensajeCapacitacion = "Vuelve a intertarlo más tarde.";
        }
      }
    );
  }


  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  /**
   * Seleccione la pregunta que se va a editar
   *
   * @param Integer   posicion del array de las preguntas
   *
   * @returns datos de la pregunta en el recuadro de edicion
   */
  irAPregunta( index: any ){
    this.currentStep = index;
    this.pregunta = this.preguntas[index];
  }

  /**
  * Realiza el cierre de sesion
  *
  * @returns hace el cierre de sesion y redirecciona al login
  */
  logOut(){
    let datos = {
      sesion: 0
    }
    localStorage.setItem( 'sesion', JSON.stringify( datos ) );
    this.sesion = false;
    location.href='/inicio';
  }

  /* muestra las alertas de mensajes al usuario
  *
  * @param String mensaje para mostrar
  * @param Integer tiempo en milisegundos que se va a mostrar el mensaje
  * @returns muestra el mensaje
  */
  mostrarAlerta( mensaje, tiempo ){
    this.snackBar.open( mensaje, 'Aceptar', {
      duration: tiempo,
    });
  }

}
